<template>
  <section class="screen">
    <div class="form-filter mb-3 fs-12" @keyup.enter="startFilter">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Дата от</label>
            <input type="date" class="form-control" placeholder="От" v-model="filter.dateBefore">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Дата до</label>
            <input type="date" class="form-control" placeholder="До" v-model="filter.dateAfter">
          </div>
        </div>
      </div>
    </div>
    <table class="table table-hover table-bordered fs-12">
      <thead>
      <tr>
        <th colspan="1"></th>
        <th colspan="3" v-for="item in screenAnalytics" :key="item.manager_id">
          <template v-if="!item.full_name">-</template>
          <template v-else>{{ item.full_name }}</template>
        </th>
      </tr>
      <tr>
        <td colspan="1"></td>
        <td v-for="item in screenAnalytics" :key="item.manager_id">
          {{ item }}
        </td>
      </tr>
      </thead>
      <tbody>
        {{ screenAnalytics.stats }}
      </tbody>
    </table>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'screen-analytics',
  data: function () {
    return {
      filter: {
        dateBefore: this.getAfterDate(),
        dateAfter: this.getBeforeDate(),
      },
      countManager: 0,
    }
  },
  computed: {
    ...mapGetters(['screenAnalytics']),
  },
  async mounted() {
    this.getScreen();
  },
  methods: {
    getAfterDate: function () {
      var dateOffset = (24 * 60 * 60 * 1000) * 30;
      var timestamp = new Date();
      var todayDate = new Date(timestamp - dateOffset).toISOString().slice(0, 10);
      return todayDate;
    },
    getBeforeDate: function () {
      var todayDate = new Date().toISOString().slice(0, 10);
      return todayDate;
    },
    startFilter: function () {
      this.getScreen();
    },
    getScreen: function(){
      this.$store.dispatch('getScreenAnalytics', this.filter).then(() => {
        this.countManager = this.screenAnalytics.length;
      });
    },
  }
}
</script>